<template>
  <v-app id="inspire">
    <v-main>
      <v-container class="fill-height" fluid>
        <v-row align="center" justify="center">
          <v-col cols="12" sm="8" lg="6" xl="4">
            <v-card class="elevation-12">
              <v-toolbar color="primary" dark flat>
                <v-toolbar-title>Confirmaci&oacute;n de correo</v-toolbar-title>
              </v-toolbar>

              <v-card-text>
                <v-alert
                  :value="error"
                  border="left"
                  colored-border
                  type="error"
                  elevation="2"
                >
                  <strong v-if="code">{{ code }}</strong>
                  {{ message }}
                </v-alert>

                <v-alert border="left" colored-border type="info" elevation="2">
                  Hola
                  <strong>{{ userInfo.first_name }}</strong
                  >, <br />Por favor, establece tu contraseña para finalizar el
                  proceso de alta.
                </v-alert>

                <v-form ref="form">
                  <v-text-field
                    label="Email"
                    name="email"
                    prepend-icon="mdi-email"
                    type="text"
                    :value="email"
                    disabled
                    autocomplete="usename"
                  ></v-text-field>

                  <v-text-field
                    id="password"
                    label="Contraseña"
                    name="password"
                    prepend-icon="mdi-lock"
                    type="password"
                    v-model="password"
                    :rules="rules.password"
                    autocomplete="new-password"
                  ></v-text-field>

                  <v-text-field
                    id="confirmed"
                    label="Confirma Contraseña"
                    name="confirmed"
                    prepend-icon="mdi-lock"
                    type="password"
                    v-model="confirmed"
                    :rules="rules.confirmed"
                    autocomplete="new-password"
                  ></v-text-field>
                </v-form>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="resetPassword" :loading="loading"
                  >Establecer Contraseña</v-btn
                >
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>

    <!-- Loading screen -->
    <eximyr-loading :loading="loading"></eximyr-loading>
  </v-app>
</template>

<script>
import { mapGetters } from 'vuex';

import errors from '@/mixins/errors';

import { required } from '@/validators/required';
import { equalsTo } from '@/validators/equalsTo';
import { min } from '@/validators/min';

import EximyrLoading from '@/components/EximyrLoading.vue';

export default {
  mixins: [errors],

  components: {
    EximyrLoading,
  },

  data: () => ({
    token: '',
    email: '',
    password: '',
    confirmed: '',

    userInfo: {},
  }),

  computed: {
    ...mapGetters('emailConfirmation', {
      loading: 'loading',
    }),

    ...mapGetters('security', {
      isAuthenticated: 'isAuthenticated',
    }),

    // Validation errors
    rules() {
      return {
        password: [required(this), min(this, 6)],
        confirmed: [required(this), equalsTo(this, this.password)],
      };
    },
  },

  created() {
    this.logout();

    // Get the email from the
    this.email = this.$route.query.email;
    this.token = this.$route.query.token;

    const info = {
      email: encodeURIComponent(this.email),
      token: encodeURIComponent(this.token),
    };

    // Check user's token and email address
    this.$store
      .dispatch('emailConfirmation/validate', info)
      .then(response => {
        this.userInfo = response.data;
      })
      .catch(() => {
        // Go to 404 error
        return this.$router.push('/404');
      });
  },

  methods: {
    /**
     * Logout the user if its logged in
     */
    logout() {
      if (this.isAuthenticated) {
        // Logout the user if their are logged in
        this.$store.dispatch('security/logout').catch(() => {
          console.error('No logado.');
        });
      }
    },

    /**
     * Sets the password and validates the email address.
     */
    resetPassword() {
      if (!this.$refs.form.validate()) {
        return;
      }

      const payload = {
        email: encodeURIComponent(this.email),
        token: encodeURIComponent(this.token),
        password: this.password,
      };

      this.$store
        .dispatch('emailConfirmation/resetPassword', payload)
        .then(() => {
          // Go to login now
          this.$router.push({ name: 'login' });
        })
        .catch(error => {
          this.storeError(error);
        });
    },
  },
};
</script>

<style></style>
